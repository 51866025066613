import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import {
  alertActions,
  userActions,
  fetchContractFolder,
  fetchContract,
  emptyContract,
  refreshTokensCompany,
} from "../_actions";

import {
  contractFoldersService,
  contractsService,
  contractsTemplateService,
} from "../_services";

import { PrivateKey } from "./_components/PrivateKey";

import { Link } from "react-router-dom";

import { encrypt, decrypt, getPublicKey } from "../_helpers";

import { alertConstants } from "../_constants";

import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { ContractDocument } from "./ContractDocument";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";

import { ValidationModal } from "../_components";

import moment from "moment";

import { Loader } from "../_components";

import { createCharterDocumentObj, createDocumentObj } from "./common/utils";

import "./ContractFolderShow.scss";

export const ContractFolderShow = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const privateKey = useSelector((state) => state.cryptKeys.privateKey);
  const user = useSelector((state) => state.authentication.user);
  const contractFolder = useSelector((state) => state.contractFolders.item);

  // Current available templates
  const [templates, setTemplates] = useState([]);
  // All templates that would be available
  const [savedTemplates, setSavedTemplates] = useState([]);

  const [isLoaderShown, setLoaderShown] = useState(true);

  const [isValidateModalShown, setValidateModalShown] = useState(false);
  const [isDraftAgainModalShown, setDraftAgainModalShown] = useState(false);
  const [isChangeContractNameModalShown, setContractNameModalShown] =
    useState(false);

  const [generateAsked, setGenerateAsked] = useState(false);
  const [downloadAsked, setDownloadAsked] = useState([]);
  const [folderType, setFolderType] = useState("");
  const [isValidateDeleteElementModalShown, setValidateDeleteElementModalShow] =
    useState({ id: null, show: false });
  useEffect(() => {
    if (id) {
      dispatch(fetchContractFolder(id));
      dispatch(emptyContract());
    }
  }, [dispatch, id]);

  const fetchTemplates = useCallback(async () => {
    setLoaderShown(true);

    try {
      const res = await contractsTemplateService.findAll();

      setSavedTemplates(res["hydra:member"]);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    setLoaderShown(false);
  }, [dispatch]);

  const fetchStandardTemplates = useCallback(async () => {
    setLoaderShown(true);

    try {
      const res = await contractsTemplateService.findStandard(
        contractFolder?.id
      );
      setTemplates(res);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    setLoaderShown(false);
  }, [contractFolder, dispatch]);

  const fetchCharterTemplates = useCallback(async () => {
    setLoaderShown(true);

    try {
      const res = await contractsTemplateService.findCharter(
        contractFolder?.id
      );
      setTemplates(res);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    setLoaderShown(false);
  }, [contractFolder, dispatch]);

  const fetchDayTemplates = useCallback(async () => {
    setLoaderShown(true);

    try {
      const res = await contractsTemplateService.findDay(contractFolder?.id);
      setTemplates(res);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    setLoaderShown(false);
  }, [contractFolder, dispatch]);

  useEffect(() => {
    if (contractFolder) {
      if (contractFolder?.owner?.id === user?.id) {
        fetchTemplates();
      }
      setFolderType(contractFolder?.folderType);
    }
  }, [contractFolder, user, fetchTemplates]);

  useEffect(() => {
    let availableTemplates = [];
    const baseContract = contractFolder?.contracts?.find((c) =>
      c.template.codeName.includes("base")
    );
    if (baseContract) {
      switch (baseContract?.template.codeName) {
        case "baseTemplate":
          fetchStandardTemplates();
          break;
        case "baseTemplateDay":
          fetchDayTemplates();
          break;
        case "baseTemplateCharter":
          fetchCharterTemplates();
          break;
        default:
          break;
      }
    } else {
      availableTemplates = savedTemplates.filter((savedTemplate) =>
        savedTemplate.name.includes("Base")
      );
      setTemplates(availableTemplates);
    }
  }, [
    savedTemplates,
    contractFolder,
    fetchStandardTemplates,
    fetchCharterTemplates,
    fetchDayTemplates,
  ]);

  const addContractFromTemplate = async (templateId = null, templateName) => {
    if (templateId === null) {
      return;
    }

    setLoaderShown(true);

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      try {
        if (
          user.company.availableTokens !== -1 &&
          user.company.availableTokens < 0
        ) {
          dispatch(
            alertActions.setAlert(
              t("Your company doesn't have anymore token available"),
              alertConstants.ERROR
            )
          );

          return;
        }

        try {
          const templateObj = savedTemplates.find((t) => t.id === templateId);

          await contractFoldersService.addContractToFolder(
            contractFolder,
            templateObj
          );

          if (templateObj.codeName === "baseTemplate") {
            const templatesToFind = ["itinerary", "passengers", "rider"];
            const templatesToAdd = savedTemplates.filter((t) =>
              // Automatically add other templates if baseTemplate
              templatesToFind.includes(t.codeName)
            );

            for (const template of templatesToAdd) {
              await contractFoldersService.addContractToFolder(
                contractFolder,
                template
              );
            }
          }
          //update tokens
          if (user?.company?.availableTokens > 0) {
            const tokens = {
              consumedTokens: templateName === "baseTemplate" ? 3 : 1,
            };
            dispatch(refreshTokensCompany(user?.company.id, tokens));
          }
          //dispatch(updateCompany(company));

          dispatch(
            alertActions.setAlert(
              t("Contract has been added"),
              alertConstants.SUCCESS
            )
          );

          dispatch(fetchContractFolder(id));

          setLoaderShown(false);
        } catch (err) {
          dispatch(alertActions.setAlert(err, alertConstants.ERROR));
          setLoaderShown(false);
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
        setLoaderShown(false);
      }
    }
  };

  const decryptInfo = useCallback(
    (info) => {
      if (!user.publicKey) {
        return info;
      }

      let publicKeyToUse = getPublicKey(user, contractFolder);

      try {
        return decrypt(info, publicKeyToUse, privateKey);
      } catch (err) {}

      return info;
    },
    [contractFolder, privateKey, user]
  );
  const decryptShareInfo = useCallback(
    (info) => {
      if (!contractFolder?.owner?.publicKey) {
        return info;
      }

      let publicKeyToUse = getPublicKey(contractFolder.owner, contractFolder);

      try {
        return decrypt(info, publicKeyToUse, publicKeyToUse);
      } catch (err) {}

      return info;
    },
    [contractFolder]
  );
  const [, setEndDate] = useState("");
  //const [disabledEdit] = useState(false);
  const disabledEdit = false;

  useEffect(() => {
    if (id === contractFolder.id) {
      const basecontrat = contractFolder.contracts?.filter((item) => {
        return (
          item.template.codeName === "baseTemplate" ||
          item.template.codeName === "baseTemplateDay"
        );
      });

      if (basecontrat?.length !== 0) {
        setEndDate(
          basecontrat[0].fieldValues?.filter((value) => {
            return value.field?.name === "cruiseEndDate";
          })
        );
      }
    } else {
      setEndDate("");
    }
  }, [contractFolder, id]);

  const generatePdfFull = () => {
    let publicKeyToUse = getPublicKey(user, contractFolder);
    let documents = [];
    let codename = "";
    const baseContract =
      contractFolder.folderType === "transport"
        ? contractFolder.contracts.find(
            (c) => c.template.codeName === "baseTemplate"
          )
        : contractFolder.contracts.find(
            (c) => c.template.codeName === "baseTemplateCharter"
          );
    if (baseContract !== undefined) {
      codename = "baseTemplate";
    } else {
      codename = "baseTemplateDay";
    }
    if (!baseContract) {
      const dayContract = contractFolder.contracts.find(
        (c) => c.template.codeName === "baseTemplateDay"
      );

      if (!dayContract) {
        return;
      }
      const baseContractObj = createDocumentObj(
        dayContract,
        contractFolder,
        moment,
        user,
        privateKey,
        t
      );
      documents.push(baseContractObj);
    } else {
      const baseContractObj =
        contractFolder.folderType === "transport"
          ? createDocumentObj(
              baseContract,
              contractFolder,
              moment,
              user,
              privateKey,
              t
            )
          : createCharterDocumentObj(
              baseContract,
              contractFolder,
              moment,
              user,
              privateKey,
              t
            );
      documents.push(baseContractObj);

      const itineraryContract = contractFolder.contracts.find(
        (c) => c.template.codeName === "itinerary"
      );
      if (itineraryContract) {
        const itineraryContractObj = createDocumentObj(
          itineraryContract,
          contractFolder,
          moment,
          user,
          privateKey,
          t
        );
        documents.push(itineraryContractObj);
      }

      let passengersContract = contractFolder.contracts.find(
        (c) => c.template.codeName === "passengers"
      );

      if (passengersContract) {
        const fieldValues = passengersContract?.fieldValues.filter(
          (fv) => !fv?.field?.hidden
        );
        passengersContract = {
          ...passengersContract,
          fieldValues,
        };
        const passengersContractObj = createDocumentObj(
          passengersContract,
          contractFolder,
          moment,
          user,
          privateKey,
          t
        );
        documents.push(passengersContractObj);
      }

      const riderContract = contractFolder.contracts.find(
        (c) => c.template.codeName === "rider"
      );
      if (riderContract) {
        const riderContractObj = createDocumentObj(
          riderContract,
          contractFolder,
          moment,
          user,
          privateKey,
          t
        );
        documents.push(riderContractObj);
      }
    }

    const legalMentionsDocument = {
      contract:
        contractFolder.lang === "en" ? "legalMentions" : "legalMentionsFr",
      jsonView: null,
    };

    documents.push(legalMentionsDocument);

    const decryptSecondArg =
      contractFolder?.sharedBrokers.length > 0 ||
      contractFolder?.createdFromShared
        ? contractFolder.owner.publicKey
        : publicKeyToUse;

    const decryptThirdArg =
      contractFolder?.sharedBrokers.length > 0 ||
      contractFolder?.createdFromShared
        ? contractFolder.owner.publicKey
        : privateKey;
    let filename = decrypt(
      contractFolder.name,
      decryptSecondArg,
      decryptThirdArg
    );

    filename += ".pdf";

    return (
      <PDFDownloadLink
        document={
          <Document>
            {documents.map((doc, index) => (
              <ContractDocument
                key={index}
                contract={doc.contract}
                jsonView={doc.jsonView}
                status={contractFolder.status}
                user={user}
                lang={contractFolder.lang}
                codename={codename}
                type={contractFolder.folderType}
              />
            ))}
          </Document>
        }
        fileName={filename}
      >
        {({ blob, url, loading, error }) => (
          <Button variant="secondary" disabled={loading}>
            {loading ? t("Loading") + "..." : t("Download")}
          </Button>
        )}
      </PDFDownloadLink>
    );
  };

  const onDraftAgainOptionSelected = async (option) => {
    setDraftAgainModalShown(false);

    if (option === "yes") {
      try {
        /*         const updatedFolder = {
          id: contractFolder.id,
          status: "draft",
          hasConsumedToken: false,
          version: contractFolder.version + 1,
        }; */

        const updatedFolder = await contractFoldersService.setDraftOrContract(
          contractFolder.id
        );
        dispatch(fetchContractFolder(contractFolder.id));

        dispatch(
          alertActions.setAlert(
            t("Your contract is in Draft again."),
            alertConstants.SUCCESS
          )
        );

        // Save to history
        // addHistory();

        // Update user for company token refresh
        let userInLocal = JSON.parse(localStorage.user);
        if (userInLocal.refresh_token) {
          dispatch(userActions.refreshToken(userInLocal.refresh_token));
        }

        // Update shared
        if (contractFolder.sharedBrokers?.length > 0) {
          for (let shared of contractFolder.sharedBrokers) {
            const updated = {
              id: shared.id,
              status: updatedFolder.folder.status,
              version: updatedFolder.folder.version,
            };

            try {
              await contractFoldersService.update(updated);
            } catch (err) {}
          }
        }

        //dispatch(fetchContractsByBroker(user.id))
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const onModalOptionSelected = async (option) => {
    setValidateModalShown(false);

    if (option === "yes") {
      if (
        user.company.availableTokens !== -1 &&
        user.company.availableTokens < 0
      ) {
        dispatch(
          alertActions.setAlert(
            t("Your company doesn't have anymore token available"),
            alertConstants.ERROR
          )
        );

        return;
      }

      // Find baseTemplate
      const baseContract = contractFolder.contracts.find(
        (elem) =>
          elem.template.codeName === "baseTemplate" ||
          elem.template.codeName === "baseTemplateDay" ||
          elem.template.codeName === "baseTemplateCharter"
      );
      if (!baseContract) {
        dispatch(
          alertActions.setAlert(
            t(
              "You have to fill base contract in order to validate your contract"
            ),
            alertConstants.ERROR
          )
        );
        return;
      }

      if (!baseContract.valid) {
        dispatch(
          alertActions.setAlert(
            t("Base contract has to be valid"),
            alertConstants.ERROR
          )
        );
        return;
      }

      try {
        await contractFoldersService.setDraftOrContract(contractFolder.id);
        dispatch(fetchContractFolder(contractFolder.id));

        dispatch(
          alertActions.setAlert(
            t("Your contract has been validated."),
            alertConstants.SUCCESS
          )
        );

        // Save to history
        //addHistory();

        // Update user for company token refresh
        let userInLocal = JSON.parse(localStorage.user);
        if (userInLocal.refresh_token) {
          dispatch(userActions.refreshToken(userInLocal.refresh_token));
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const onDeleteElementOptionSelected = async (option) => {
    switch (option) {
      case "yes":
        deleteContract(isValidateDeleteElementModalShown.id);
        setValidateDeleteElementModalShow({ id: null, show: false });

        break;
      case "no":
        setValidateDeleteElementModalShow({ id: null, show: false });
        break;
      default:
        break;
    }
  };
  /* const addHistory = async () => {
    const baseContract = contractFolder.contracts.find(
      (elem) =>
        elem.template.codeName === "baseTemplate" ||
        elem.template.codeName === "baseTemplateDay" ||
        elem.template.codeName === "baseTemplateCharter"
    );

    // Save to history
    let contractFields = [];

    for (const fieldValue of baseContract.fieldValues) {
      const field = {
        name: fieldValue.field?.name,
        label: fieldValue.field?.label,
        value: decrypt(fieldValue.value, user.publicKey, privateKey),
      };

      contractFields.push(field);
    }

    try {
      // Need to find : agreementUniqueNumber / cruiseStartDate / cruiseEndDate / cruiseArea / yachtLOADinMeters
      const agreementUniqueNumber = contractFields.find(
        (el) => el.name === "agreementUniqueNumber"
      ).value;
      const cruiseStartDate = contractFields.find(
        (el) => el.name === "cruiseStartDate"
      ).value;
      const cruiseEndDate = contractFields.find(
        (el) => el.name === "cruiseEndDate"
      ).value;
      const cruisingArea = contractFields.find(
        (el) => el.name === "cruisingArea"
      ).value;
      const yachtLength = Number(
        contractFields.find(
          (el) =>
            el.name === "yachtLOADinMeters" ||
            el.name === "CharterYachtLOADinMeters"
        ).value
      );

      const duration = String(
        moment(cruiseEndDate).diff(moment(cruiseStartDate))
      );

      let history = {
        agreementUniqueNumber,
        duration,
        cruisingArea,
        yachtLength,
        broker: `/brokers/${user.id}`,
      };

      const existingHistory = await contractHistoryService.getByAgreementNumber(
        agreementUniqueNumber
      );
      if (existingHistory["hydra:totalItems"] > 0) {
        history.id = existingHistory["hydra:member"][0].id;

        const newHistory = await contractHistoryService.update(history);
      } else {
        const newHistory = await contractHistoryService.add(history);
      }
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  }; */

  const isBaseTemplateEdited = () => {
    return true;
  };
  const [newContractName, setNewContractName] = useState();

  useEffect(() => {
    const decrypted =
      contractFolder?.owner?.id !== user?.id
        ? decryptShareInfo(contractFolder.name)
        : contractFolder?.sharedBrokers.length > 0
        ? decryptShareInfo(contractFolder.name)
        : decryptInfo(contractFolder.name);
    setNewContractName(decrypted);
  }, [decryptInfo, decryptShareInfo, contractFolder, user, privateKey]);
  const deleteContract = async (id) => {
    try {
      await contractsService.remove(id);
      dispatch(
        alertActions.setAlert(
          t("This element has been removed"),
          alertConstants.SUCCESS
        )
      );

      dispatch(fetchContractFolder(contractFolder.id));
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  const downloadContract = (id) => {
    let contract = contractFolder?.contracts.find((c) => c.id === id);
    if (!contract) {
      return <></>;
    }
    const fieldValues = contract?.fieldValues.filter(
      (fv) => !fv?.field?.hidden
    );
    contract = {
      ...contract,
      fieldValues,
    };
    const document =
      contractFolder.folderType === "transport"
        ? createDocumentObj(
            contract,
            contractFolder,
            moment,
            user,
            privateKey,
            t
          )
        : createCharterDocumentObj(
            contract,
            contractFolder,
            moment,
            user,
            privateKey,
            t
          );

    if (!document) {
      return <></>;
    }

    return (
      <PDFDownloadLink
        document={
          <Document>
            <ContractDocument
              contract={document.contract}
              jsonView={document.jsonView}
              status={contractFolder.status}
              user={user}
              type={contractFolder.folderType}
              lang={contractFolder.lang}
            />
          </Document>
        }
        fileName={document.filename}
      >
        {({ blob, url, loading, error }) => (
          <Button
            style={{ marginLeft: "10px" }}
            variant="info"
            size="sm"
            title={t("Download")}
          >
            <i className="fa fa-download"></i>
          </Button>
        )}
      </PDFDownloadLink>
    );
  };

  const askToDownloadContract = (id) => {
    setDownloadAsked({ ...downloadAsked, [id]: true });
  };

  const onChangeContractName = async () => {
    let data = {
      id: contractFolder.id,
      name: encrypt(newContractName, user.publicKey, privateKey),
    };
    await contractFoldersService.renameContractFolder(contractFolder.id, data);
    dispatch(fetchContractFolder(contractFolder.id));
    setContractNameModalShown(false);
  };
  const validateCharterMessage =
    "<p>" +
    t(
      "WARNING ! This action cannot be undone. You won't be able to edit this contract anymore, unless you go back to draft. You will need another token to validate again."
    ) +
    "</p>" +
    "<p>" +
    t("Do you wish to continue ?") +
    "</p>";
  const validateTransportMessage =
    "<p>" +
    t(
      "WARNING ! This action cannot be undone. You won't be able to edit this contract anymore, unless you go back to draft. You will need another token to validate again."
    ) +
    "</p>" +
    "<p>" +
    t("Do you wish to continue ?") +
    "</p>" +
    "<p>" +
    "<strong>" +
    t(
      "ECPY reminds you that the captain should have full contract build in one document"
    ) +
    " : " +
    "</strong>" +
    "</p>" +
    " <ul>" +
    "<li>" +
    t("4 first pages of the contract, with signatures,") +
    "</li>" +
    "<li>" +
    t(
      "appendix I with detailled itinerary and passengers list, fixed at departure (pp 5-7)"
    ) +
    "</li>" +
    "<li>" +
    t(
      "optional modifications to itinerary and passengers list with Client and Captain paraphs (pp 8-9)"
    ) +
    "</li>" +
    "</ul>" +
    "<p>" +
    t(
      'In order to do that, you have to use the "Generate full contract" feature, stricly regarding the previous recommandations.'
    ) +
    " " +
    "<br />" +
    t(
      "Mail or alone page, non attached to the contrat and not signed, is not a relevant element nor modification legally accepted."
    ) +
    "</p>";

  const validateDeleteElement = t(
    "Are you sure you want to permanently delete this element ?"
  );
  return (
    <>
      <Loader isShown={isLoaderShown} />

      <ValidationModal
        title={t("Validate your draft")}
        text={
          contractFolder.folderType === "transport"
            ? validateTransportMessage
            : validateCharterMessage
        }
        show={isValidateModalShown}
        onOptionSelected={onModalOptionSelected}
      />
      <ValidationModal
        title={t("Delete an element")}
        text={validateDeleteElement}
        show={isValidateDeleteElementModalShown.show}
        onOptionSelected={onDeleteElementOptionSelected}
      />

      <ValidationModal
        title={t("Set draft again")}
        text={
          "<p>" +
          t(
            "Warning ! Tokens will be consumed when you validate your contract again after ending your draft."
          ) +
          "</p>" +
          "<p>" +
          t("Do you wish to continue ?") +
          "</p>"
        }
        show={isDraftAgainModalShown}
        onOptionSelected={onDraftAgainOptionSelected}
      />
      <Modal
        show={isChangeContractNameModalShown}
        onHide={() => setContractNameModalShown(false)}
      >
        <Modal.Header>
          <Modal.Title>{t("Change new contract name")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="userEmail">
              <Form.Control
                type="text"
                value={newContractName}
                onChange={(e) => setNewContractName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={onChangeContractName}>
            {t("Yes")}
          </Button>

          <Button
            variant="danger"
            onClick={() => setContractNameModalShown(false)}
          >
            {t("No")}
          </Button>
        </Modal.Footer>
      </Modal>

      <PrivateKey />

      {user.publicKey &&
      privateKey &&
      privateKey !== "" &&
      contractFolder &&
      contractFolder.id ? (
        <>
          <h1 className="page-title">
            {contractFolder.owner.id !== user.id
              ? decryptShareInfo(contractFolder.name)
              : contractFolder?.sharedBrokers.length > 0
              ? decryptShareInfo(contractFolder.name)
              : decryptInfo(contractFolder.name)}{" "}
            (v.{contractFolder.version})
            <Button
              variant="primary"
              size="sm"
              className="ml-2"
              onClick={() =>
                setContractNameModalShown(!isChangeContractNameModalShown)
              }
            >
              {t("Rename")}
            </Button>{" "}
            <small>
              {contractFolder.status === "draft" && (
                <Badge pill variant="warning" style={{ marginLeft: "15px" }}>
                  {t("Draft")}
                </Badge>
              )}
              {contractFolder.status === "contract" && (
                <Badge pill variant="success" style={{ marginLeft: "15px" }}>
                  {t("Contract")}
                </Badge>
              )}
            </small>
          </h1>

          {
            <>
              {contractFolder.folderType === "transport"
                ? templates
                    ?.filter(
                      (temp) =>
                        temp.codeName !== "baseTemplateCharter" &&
                        temp.codeName !== "AddendumCharter"
                    )
                    .map((template) => (
                      <Button
                        key={template.codeName}
                        onClick={(e) =>
                          addContractFromTemplate(
                            template.id,
                            template.codeName
                          )
                        }
                        variant="secondary"
                        style={{
                          position: "relative",
                          marginRight: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <OverlayTrigger
                          key="new"
                          placement="top"
                          overlay={<Tooltip id="see">{t("new")}</Tooltip>}
                        >
                          <Button
                            variant="success"
                            style={{ padding: "0 5px" }}
                            className="contract__innerBtn"
                          >
                            +
                          </Button>
                        </OverlayTrigger>{" "}
                        <span style={{ marginLeft: "30px" }}>
                          {t(template.name)}
                        </span>
                      </Button>
                    ))
                : templates
                    ?.filter(
                      (temp) =>
                        temp.codeName === "baseTemplateCharter" ||
                        temp.codeName === "AddendumCharter"
                    )
                    .map((template) => (
                      <Button
                        key={template.codeName}
                        onClick={(e) =>
                          addContractFromTemplate(
                            template.id,
                            template.codeName
                          )
                        }
                        variant="secondary"
                        style={{
                          position: "relative",
                          marginRight: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <OverlayTrigger
                          key="new"
                          placement="top"
                          overlay={<Tooltip id="see">{t("new")}</Tooltip>}
                        >
                          <Button
                            variant="success"
                            style={{ padding: "0 5px" }}
                            className="contract__innerBtn"
                          >
                            +
                          </Button>
                        </OverlayTrigger>{" "}
                        <span style={{ marginLeft: "30px" }}>
                          {t(template.name)}
                        </span>
                      </Button>
                    ))}
            </>
          }

          <Row style={{ marginTop: "15px" }}>
            {contractFolder?.contracts.map((contract) => (
              <Col key={contract.id} md={4}>
                <div className="contract__item">
                  <h3 className="contract__title">
                    <>{t(contract.template.name)}</>
                  </h3>

                  <div className="contract__state">
                    {contract.valid ? (
                      <>
                        <Button variant="success" size="sm">
                          <i className="fa fa-check"></i>
                        </Button>
                        {t("Complete")}
                      </>
                    ) : (
                      <>
                        <Button variant="danger" size="sm">
                          <i className="fa fa-times"></i>
                        </Button>
                        {t("Incomplete")}
                      </>
                    )}
                  </div>

                  <div className="contract__btns">
                    {((contract.template.codeName !== "baseTemplate" &&
                      contract.template.codeName !== "baseTemplateCharter" &&
                      contract.template.codeName !== "baseTemplateDay" &&
                      !contract.locked) ||
                      ((contract.template.codeName === "baseTemplate" ||
                        contract.template.codeName === "baseTemplateCharter" ||
                        contract.template.codeName === "baseTemplateDay") &&
                        contractFolder.status === "draft")) &&
                      (folderType === "transport" ? (
                        <Link to={`/contracts/${contract.id}`}>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => dispatch(fetchContract(contract.id))}
                            disabled={
                              contractFolder.owner.id !== user.id
                                ? false
                                : disabledEdit
                            }
                          >
                            {contractFolder.owner.id !== user.id
                              ? t("See")
                              : t("Edit")}
                          </Button>
                        </Link>
                      ) : folderType === "charter" ? (
                        <Link to={`/charters/${contract.id}`}>
                          <Button
                            variant="primary"
                            size="sm"
                            disabled={
                              contractFolder.owner.id !== user.id
                                ? false
                                : disabledEdit
                            }
                          >
                            {contractFolder.owner.id !== user.id
                              ? t("See")
                              : t("Edit")}
                          </Button>
                        </Link>
                      ) : (
                        <Link></Link>
                      ))}
                    {contract.valid === false && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        variant="primary"
                        size="sm"
                        title={t("")}
                        onClick={async () => {
                          setLoaderShown(true);

                          const isInvalid =
                            contract?.fieldValues.length === 0
                              ? true
                              : contract.fieldValues.some((fieldValue) => {
                                  return (
                                    fieldValue.value === null &&
                                    fieldValue.field.required === true
                                  );
                                });

                          if (isInvalid === true) {
                            dispatch(
                              alertActions.setAlert(
                                t("The contract is not valid"),
                                alertConstants.ERROR
                              )
                            );
                          } else {
                            const updatedContract = {
                              id: contract.id,
                              valid: true,
                            };
                            await contractsService.update(updatedContract);
                            dispatch(fetchContractFolder(contractFolder.id));
                            dispatch(
                              alertActions.setAlert(
                                t("Your contract has been validated."),
                                alertConstants.SUCCESS
                              )
                            );
                          }
                          setLoaderShown(false);
                        }}
                      >
                        {t("Verify the document / Generate")}
                      </Button>
                    )}

                    {contract.valid &&
                      contractFolder.owner.id === user.id &&
                      (folderType === "transport" ? (
                        <Link to={`/contracts/${contract.id}?mode=seen`}>
                          <Button
                            variant="primary"
                            style={{ marginLeft: "10px" }}
                            size="sm"
                          >
                            {t("See")}
                          </Button>
                        </Link>
                      ) : folderType === "charter" ? (
                        <Link to={`/charters/${contract.id}?mode=seen`}>
                          <Button
                            variant="primary"
                            style={{ marginLeft: "10px" }}
                            size="sm"
                          >
                            {t("See")}
                          </Button>
                        </Link>
                      ) : (
                        <link></link>
                      ))}
                    {contract.valid &&
                      contract.template.codeName !== "AddendumDAY" &&
                      downloadAsked[contract.id] === true &&
                      downloadContract(contract.id)}
                    {contract.valid && !downloadAsked[contract.id] && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        variant="outline-primary"
                        size="sm"
                        title={t("Generate")}
                        onClick={() => askToDownloadContract(contract.id)}
                      >
                        {t("Generate")}
                      </Button>
                    )}
                    {contract.template.codeName !== "baseTemplateDay" &&
                      contractFolder.status === "draft" &&
                      contractFolder.owner.id === user.id && (
                        <Button
                          style={{ marginLeft: "10px" }}
                          variant="outline-primary"
                          className="custom"
                          size="sm"
                          title={t("Delete")}
                          onClick={() =>
                            setValidateDeleteElementModalShow({
                              id: contract.id,
                              show: true,
                            })
                          }
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <div style={{ textAlign: "left", marginTop: "15px" }}>
            {contractFolder.status === "draft" &&
              isBaseTemplateEdited() &&
              contractFolder.owner.id === user.id && (
                <Button
                  style={{ marginRight: "15px" }}
                  variant="danger"
                  onClick={() => setValidateModalShown(true)}
                  size="lg"
                >
                  {t("Validate the contract")}
                </Button>
              )}

            {contractFolder.status === "contract" &&
              contractFolder.owner.id === user.id && (
                <Button
                  style={{ marginRight: "15px" }}
                  variant="danger"
                  onClick={() => setDraftAgainModalShown(true)}
                >
                  {t("Set draft again")}
                </Button>
              )}

            {isBaseTemplateEdited() && !generateAsked && (
              <Button
                variant="outline-primary"
                onClick={() => setGenerateAsked(true)}
                size="lg"
              >
                {contractFolder.status === "contract"
                  ? t("Generate full contract")
                  : t("Generate full draft")}
              </Button>
            )}
            {isBaseTemplateEdited() && generateAsked && generatePdfFull()}
          </div>
        </>
      ) : (
        <p>
          {t("You must set your private key to be able to see your contracts.")}
        </p>
      )}
    </>
  );
};
