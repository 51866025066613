import axios from "axios";

import { ApiService } from "./api";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contracts");

export const contractsService = {
  findAll,
  find,
  add,
  update,
  remove,
  findByBroker,
  addApiPlatform,
};

async function add(item) {
  return apiService.add(item);
}

async function addApiPlatform(item) {
  return apiService.addApiPlatform(item);
}

async function findAll(page = 1) {
  return apiService.findAll(page);
}

async function find(id) {
  return apiService.find(id);
}

async function update(item) {
  try {
    await axios.put(
      `${apiService.getApiUrl()}/status/${item.id}`,
      item,
      authHeader()
    );
  } catch (error) {}
  /*   return apiService.update(item)
   */
}

async function remove(id) {
  return apiService.remove(id);
}

async function findByBroker(id, page = 1) {
  let params = authHeader();
  params.params = { owner: `/brokers/${id}`, page: page };

  try {
    const res = await axios.get(`${apiService.apiUrl}`, params);

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}
