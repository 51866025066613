import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { PrivateKey } from "./_components/PrivateKey";

import { fetchContract, fetchContractFolder } from "../_actions";
import { encrypt, decrypt } from "../_helpers";

import { ContractForm } from "./ContractForm";

import { contractsService, contractFieldValuesService } from "../_services";

import { Alert } from "react-bootstrap";

import { alertActions } from "../_actions";
import { alertConstants } from "../_constants";

import { Loader } from "../_components";

import "./ContractEdit.scss";

export const ContractEdit = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const privateKey = useSelector((state) => state.cryptKeys.privateKey);
  const contract = useSelector((state) => state.contracts.item);
  const user = useSelector((state) => state.authentication.user);

  const [, setContractLinkShown] = useState(false);
  const [isLoaderShown, setLoaderShown] = useState(true);
  const contractFolder = useSelector((state) => state.contractFolders.item);

  useEffect(() => {
    dispatch(fetchContract(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.values(contract).length > 0) {
      dispatch(fetchContractFolder(contract.contractFolder.id));
      setLoaderShown(false);
    }
  }, [contract, dispatch]);

  const renderContract = () => {
    if (contract.name === "") {
      return;
    }

    return (
      <>
        <h2 className="page-subtitle">
          {contract.name &&
            user.publicKey &&
            privateKey &&
            (contractFolder?.sharedBrokers > 0 ||
            contractFolder?.createdFromShared
              ? decrypt(
                  contract.name,
                  contractFolder.owner.publicKey,
                  contractFolder.owner.publicKey
                )
              : decrypt(contract.name, user.publicKey, privateKey))}
        </h2>
      </>
    );
  };

  const handleFormSubmit = async (values, isValid, deleteAction = false) => {
    let fieldsToAdd = [];

    for (const index in values) {
      let value = String(values[index]);

      try {
        let fieldValue = contract?.fieldValues.find(
          (item) => item.field && item.field.id === index
        );
        let newFieldValue = {};
        const decryptSecondArg =
          contractFolder?.sharedBrokers.length > 0
            ? contractFolder?.owner.publicKey
            : user?.publicKey;

        const decryptThirdArg =
          contractFolder?.sharedBrokers.length > 0
            ? contractFolder?.owner.publicKey
            : privateKey;

        if (fieldValue) {
          /* const decrypted = decrypt(
            fieldValue.value,
            decryptSecondArg,
            decryptThirdArg
          ); */
          // if (decrypted !== "") {
          newFieldValue = {
            id: fieldValue.id,
            contract: `/contracts/${contract.id}`,
            field: `/contract_fields/${index}`,
            value: encrypt(value, decryptSecondArg, decryptThirdArg),
          };
          //}
        } else {
          if (value !== "") {
            newFieldValue = {
              contract: `/contracts/${contract.id}`,
              field: `/contract_fields/${index}`,
              value: encrypt(value, decryptSecondArg, decryptThirdArg),
            };
          }
        }
        fieldsToAdd.push(newFieldValue);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }

    if (fieldsToAdd.length > 0) {
      try {
        fieldsToAdd = fieldsToAdd.filter(
          (field) => Object.keys(field).length !== 0
        );
        //if (!deleteAction)
        await contractFieldValuesService.addMultiple(fieldsToAdd);
        if (isValid !== contract.valid) {
          let updatedContract = {
            id: contract.id,
            valid: !contract.valid,
          };
          await contractsService.update(updatedContract);
        }

        dispatch(fetchContract(contract.id));

        dispatch(
          alertActions.setAlert(
            t("Contract has been saved"),

            alertConstants.SUCCESS
          )
        );
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }

    /*  if (contractFolder.sharedBrokers?.length > 0) {
      const contractResObj = await contractsService.find(contract.id); */
    /*       const contractFolder = await contractFoldersService.find(
        contractFolder.id
      ); */

    /* Update shared instances */
    /*       let fieldValuesToAdd = [];
      for (let shared of contractFolderItem.sharedBrokers) {
        const sharedContract = shared.contracts.find(
          (c) => c.template.codeName === contractResObj.template.codeName
        );

        if (sharedContract) {
          if (sharedContract.valid != isValid) {
            let updatedContract = {
              id: sharedContract.id,
              valid: isValid,
            };

            try {
              const contractRes = await contractsService.update(
                updatedContract
              );
            } catch (err) {}
          }

          // Remove all fields values
          const fieldsValuesRemoveRes =
            await contractFieldValuesService.removeMultiple(
              sharedContract.fieldValues
            );

          for (let fieldValue of contractResObj.fieldValues) {
            const decryptedValue = decrypt(
              fieldValue.value,
              user.publicKey,
              privateKey
            );

            const newEncryptedValue = encrypt(
              decryptedValue,
              shared.sharedTo.publicKey,
              privateKey
            );

            const newFieldValue = {
              contract: `/contracts/${sharedContract.id}`,
              field: `/contract_fields/${fieldValue.field.id}`,
              value: newEncryptedValue,
            };

            fieldValuesToAdd.push(newFieldValue);
          }
        }
      }

      // Add all
      const fvRes = await contractFieldValuesService.addMultiple(
        fieldValuesToAdd
      ); */
    // }

    //return true;
  };

  const onRefreshCalled = () => {
    dispatch(fetchContract(id));
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      <PrivateKey />

      <h1 className="page-title">
        {t("Edit contract")} (
        {contract?.template?.name.startsWith("Addendum") ? (
          <>
            {t("Addendum")} {contract?.template?.name.slice(8)}
          </>
        ) : (
          <>{t(contract?.template?.name)}</>
        )}
        )
      </h1>

      {contract?.template?.codeName === "baseTemplate" && (
        <Alert variant="warning">
          {t(
            "Warning ! Base contract (page 1 to 4) has to be fully filled before signature."
          )}
        </Alert>
      )}

      {(contract?.template?.codeName === "itinerary" ||
        contract?.template?.codeName === "passengers" ||
        contract?.template?.codeName === "rider") && (
        <Alert variant="warning">
          {t("Warning ! This part has to be filled before departure.")}
        </Alert>
      )}

      <p style={{ fontWeight: "bold" }}>{t("Fields with * are mandatory")}</p>

      {user.publicKey &&
      privateKey &&
      privateKey !== "" &&
      contract &&
      contract.id ? (
        <div className="contract-edit">
          {contract.template.codeName !== "baseTemplate" ||
          contractFolder.status === "draft" ? (
            <>
              {renderContract()}

              <ContractForm
                template={contract.template}
                onValidSubmit={handleFormSubmit}
                onGenerateAsk={() => {
                  setContractLinkShown(true);
                  /* if (contract.template.codeName == 'addendum') {
                    lockAddendum()
                  }*/
                }}
                setContractLinkShown={setContractLinkShown}
                onRefreshCalled={onRefreshCalled}
                createdAt={contract.createdAt}
                id={id}
              />

              {/*isContractLinkShown && (
                <div style={{ textAlign: "center" }}>
                  <hr style={{ borderTop: "1px solid #cccccc" }} />
                  {renderContractPdf()}
                </div>
              )*/}
            </>
          ) : (
            <>
              {renderContract()}
              <ContractForm
                template={contract.template}
                onGenerateAsk={() => {
                  setContractLinkShown(true);
                }}
                setContractLinkShown={setContractLinkShown}
                createdAt={contract.createdAt}
                id={id}
              />
            </>
          )}
        </div>
      ) : (
        <p>
          {t("You must set your private key to be able to edit this contract.")}
        </p>
      )}
    </>
  );
};
